import React, { FC, Fragment } from 'react';
import { graphql, PageProps, Link } from 'gatsby';

import { Layout } from '../components/Layout';
import { AuthorData } from '../types';

interface DataProps {
    site: {
        siteMetadata: {
            title: string;
        }
    },
    allAuthors: {
        nodes: AuthorData[];
    }
}

const Authors: FC<PageProps<DataProps>> = ({ data, location }) => {
    const { site, allAuthors } = data;

    return (
        <Layout seoTitle='Авторы' location={location}>
            <h1>Авторы</h1>
            {allAuthors.nodes.map(({ name, slug}) => (
                <div key={slug} style={{ marginBottom: 20 }}>
                    <Link to={`/authors/${slug}`}><h3>{name}</h3></Link>
                </div>
            ))}
        </Layout>
    );
};

export default Authors;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allAuthors {
            nodes {
                _id
                name
                slug
            }
        }
    }
`;
